import React, { FunctionComponent } from "react"
import "../components/layout.css"
import styled, { createGlobalStyle } from "styled-components";
import pressImage from "../images/akersborg-album-cover-feelantropicoco.jpg"
/* import logo01 from "../images/logo01.png"
import logo02 from "../images/logo02.png"
import logo03 from "../images/logo03.png"
import live from "../images/akersborg-live.jpg"
import javlaliv from "../image/javla-liv-cover-full-size.jpg"
 */
const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh !important;
  overflow: scroll !important;
`;

const Container = styled.div`
  width: 50%;
  background: white;
  min-height: 100vh !important;
  overflow: auto !important;
  padding: 60px;
  margin: 60px 0;

  @media screen and (max-width: 1020px) { 
    width: 85%;
    padding: 40px;
    margin: 60px 0;
  }
  @media screen and (max-width: 750px) { 
    width: 90%;
    padding: 30px;
    margin: 30px 0;
  }

`;

const PressImage = styled.img.attrs(() =>({
  src: pressImage
}))`
`

/* const Logo01 = styled.img.attrs(() =>({
  src: logo01
}))`
`
const Logo02 = styled.img.attrs(() =>({
  src: logo02
}))`
`
const Logo03 = styled.img.attrs(() =>({
  src: logo03
}))`
`
const Live = styled.img.attrs(() =>({
  src: live
}))`
`
const JavlaLiv = styled.img.attrs(() =>({
  src: javlaliv
}))`
` */


const Presseskriv: FunctionComponent = () => {

  return (
    <div className="layout">
      <OuterContainer>
        <Container>

          <h1 id="Presseskriv">Pressemelding Akersborg - “Feelantropicoco”</h1>

        <br/>

          <p>Det helt nye hardcore-rockebandet Akersborg lanserer debutalbummet “Feelantropicoco” <strong> 17. november 2023 </strong> på Vinter Records. Noen beskrivelser av Akersborg til nå har vært blant annet: </p>

          <p> <strong>“Skamrått!”</strong> - Jon V. Nguyen, NRK <br/>
<strong> “Spaced out brilliance” </strong> - Ole C Helstad, bookingsjef Revolver <br/>
<strong> “Hva skal man si om Akersborg?” </strong> - Evig Lyttar <br/>
<strong> “wtf I didn’t know hardcore could sound like this, pure unpredictable amusement!”</strong> - Nikoline Spjelkavik fra Witch Club Satan 
  <br/>
  </p>

          
<p>Det er vanskelig å sette en merkelapp på Akersborg, men selv kaller de det “catchy avant-garde hardcore”. </p>
<p>Startet av Dag von Krogh Bunkholt i 2022 sammen med Tobias Ørnes Andersen og Simen Følstad Nilsen fra Aiming for Enrike, Jakob Jones og Torgeir Beyer. </p>
<p>Etter å ha lansert to singler ble Akersborg fremhevet av NRK Urørt og fikk en plass under klubbdagen på Øyafestivalen 2022. </p>
<p>Ryktene gikk fort om en svært spesiell liveopptreden uten om det vanlige, og førte til en platekontrakt med Vinter Records for det kommende debutalbumet som slippes 17. november 2023 fysisk og digitalt. </p>
<p>“Feelantropicoco” er et sammensatt og et litt artig ord fulle av kontraster. Dette speiler musikken som går fort unna, og fra den ene sinnsstemningen til den andre som en scroll nedover Instagram. “Feelantropicoco” forsøker å ta like mye del i det raske livet vi lever i dag som den prøver å kommenterer det. </p>
<p>Med som gjestevokalister finner vi blant annet Agnete Kjølsrud fra Djerv og Animal Alpha, Maciek Ofstad fra Kvelertak, Anders Ugmod fra Beaten To Death og Bendik Brænne. </p>
<p>Det har blitt sakt at “Feelantropicoco” kan bli 2020-talles svar på Refuseds - “A Shape Of Punk To Come”. </p>


<br/>
          <h3> Plateomslag Feelantropicoco </h3>
          <PressImage />
          <span> Høyoppløslig, 4060px x 4060px, 11,4MB </span>
          <br/>
          <br/>
          <h3>Forhåndslytt til album</h3>
          <a href="https://soundcloud.com/dag-von-krogh-bunkholt/albums" target="_blank"> https://soundcloud.com/dag-von-krogh-bunkholt/albums </a> 
          <br/>
          <br/>
          <h3>Linker</h3>
          <a href="https://akersborg.com" target="_blank"> https://akersborg.com </a> <br/>
          <a href="https://www.instagram.com/akersborg/" target="_blank"> https://www.instagram.com/akersborg/ </a> 
            <br/>
          <a href="https://open.spotify.com/artist/2V1MHqYRUYdRIqIHfYI4R0?si=CeWqWx2lTFuy6CkleE9hVA" target="_blank"> Akersborg på Spotify </a> <br/>
          <br/>
          <h3>Kontakt</h3>
            <p>Dag von Krogh Bunkholt - Akersborg - 📞 93202707 - 📧 <a href="akersborgband@gmail.com">akersborgband@gmail.com</a>
            <br/>
              Christer Kaupang - Vinter Records - 📧 <a href="christer@vinterrecords.com ">christer@vinterrecords.com </a>
            </p>
            <br/>
            <br/>
{/*             <h3>Annen media</h3>
            <p>
              <strong> Logo</strong>
            <Logo01/> <br/>
            <Logo02/> <br/>
            <Logo03/> <br/>
            </p>
            <p>
              <strong>Live</strong>
              <Live/>
            </p>
            <p>
              <strong>Single release "Et jävla liv vi lever"</strong>
              <JavlaLiv/>
            </p> */}
            
        </Container>
      </OuterContainer>
      </div>
    ) 
  }

export default Presseskriv;
